import React from "react";
import {
    Box,
    BreadcrumbGroup,
    Button,
    ButtonDropdown,
    ColumnLayout,
    Container,
    Header,
    ProgressBar,
    StatusIndicator,
    SpaceBetween,
    Table
  } from "@cloudscape-design/components";

function WorkshopHeader(props) {
  return (
    <Container><ColumnLayout columns={3} variant="text-grid">
    <SpaceBetween size="l">
      <div>
        <Box variant="awsui-key-label">Event</Box>
        <div>{props.eventName}</div>
      </div>
    </SpaceBetween>

    <SpaceBetween size="l">
      <div>
        <Box variant="awsui-key-label">Status</Box>
        <div>
          <StatusIndicator type={props.eventData.status === "started" ? "success" : "stopped"}>
            {props.eventData.status === "started" ? "In Progress" : "Stopped"}
          </StatusIndicator>
        </div>
      </div>
    </SpaceBetween>
    <SpaceBetween size="l">


        <ProgressBar
            value={(props.currentSection/props.totalSections)*100+props.deployProgress}
            variant="key-value"
            description={(props.currentSection+1) + " / " + props.totalSections }
            label="Progress"
          />


    </SpaceBetween>
  </ColumnLayout></Container>
  )
}

export default WorkshopHeader;