import { useState, useEffect, useRef } from "react";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import SegmentedControl from "@cloudscape-design/components/segmented-control";
import Alert from "@cloudscape-design/components/alert";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import TextContent from "@cloudscape-design/components/text-content";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import { ExpandableSection } from "@cloudscape-design/components";

const hints = {
  "-1": "Start with an Amazon API Gateway. Connect it to a AWS Lambda Function, which contains the application logic. Connect this Lambda Function to all other services mentioned above.",
  "0": "You can connect your Amazon API Gateway directly to Amazon SNS. SNS can fan out to three different Amazon SQS queues, which each connect to one of the AI services trhough a Lambda Function. To simplify your architecture, you don't need to connect DynamoDB and S3."
}


function BuildYourArchitecture(props) {
  const [captureButtonLoading, setCaputreButtonLoading] = useState(false);
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [openAWSloading, setOpenAWSloading] = useState(false);

  //console.log(props.validationResult.step, props.currentSection);
  return (
    <Container header={<Header variant="h2">Build your architecture</Header>}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l" classNames={"bya-left-column"}>
          <SegmentedControl
            selectedId={props.buildStep}
            label="Default segmented control"
            options={[
              {
                text: "1. Scan",
                id: "1",
                disabled: true,
                iconName: "video-on",
              },
              {
                text: "2. Validate",
                id: "2",
                disabled: true,
                iconName: "check",
              },
              {
                text: "3. Deploy",
                id: "3",
                disabled: true,
                iconName: "upload",
              },
            ]}
          />
          {props.buildStep === "1" ? (
            <Alert
              action={
                <Button
                  loading={captureButtonLoading}
                  onClick={() => {
                    setCaputreButtonLoading(true);
                    props.requestImage();
                    setTimeout(()=>{
                      setCaputreButtonLoading(false);
                    },5000)
                  }}
                >
                  Request Image
                </Button>
              }
            >
              {captureButtonLoading
                ? ""
                : 'When you are done building your architecture, open the companion app and click on "Request Image" to activate the camera. '}
              Capture a picture of your architecture and upload it to proceed to
              the next step.
            </Alert>
          ) : (
            ""
          )}
          {props.buildStep === "2" && props.validationResult === false ? (
            <TextContent>
              <p>
                <strong>Validate your architecture.</strong>
              </p>
              <p>
                Click on "Validate" to proceed to the next step or click "Retry"
                to try again.
                <br />
                <br />
              </p>
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  disabled={validationInProgress}
                  onClick={() => {
                    setCaputreButtonLoading(true);
                    setTimeout(()=>{
                      setCaputreButtonLoading(false);
                    },5000);
                    props.retry();
                  }}
                >
                  Retry
                </Button>
                <Button
                  loading={validationInProgress}
                  variant="primary"
                  onClick={() => {
                    props.validate();
                    setValidationInProgress(true);
                  }}
                >
                  Validate
                </Button>
              </SpaceBetween>
            </TextContent>
          ) : (
            ""
          )}
          {props.buildStep === "2" && props.validationResult !== false ? (
            <TextContent>
              <SpaceBetween direction="vertical" size="xs">
              <p>
                <strong>Validate your architecture.</strong>
              </p></SpaceBetween>
              {props.validationResult !== null &&
              (props.validationResult.match === true) &
                (props.validationResult.step === ( props.currentSection + 1)) ? (
                <SpaceBetween direction="vertical" size="xs">
                    <center><h3>Congratulations, that's right!</h3></center><p>&nbsp;</p><p> Now go ahead and deploy your
                    architecture.
                  </p>
                  <Button
                    onClick={() => {
                      props.deploy();
                    }}
                  >
                    Deploy
                  </Button>
                </SpaceBetween>
              ) : (
                <SpaceBetween direction="vertical" size="m">
                  <p>Not quite yet... please try again!</p>
                  <ExpandableSection headerText="Need a hint?">
                  {hints[props.currentSection]}
                  </ExpandableSection>
                  <Button
                    onClick={() => {
                      props.retry();
                      setValidationInProgress(false);
                      setCaputreButtonLoading(true);
                    }}
                  >
                    Retry
                  </Button>
                </SpaceBetween>
              )}
            </TextContent>
          ) : (
            ""
          )}
          {props.buildStep === "3" ? (
            <TextContent>
              <SpaceBetween direction="vertical" size="l">
                <ProgressBar
                  value={props.deploymentPercentage}
                  additionalInfo={props.deploymentMessage}
                  description="Progress"
                  label={<b>Deploying your architecture</b>}
                />
                <Button
                  loading={openAWSloading}
                  onClick={async () => {
                    setOpenAWSloading(true);
                    await props.federate();
                    setOpenAWSloading(false);
                  }}
                  iconAlign="right"
                  iconName="external"
                >
                  Open AWS Account
                </Button>
                <Button
                                  iconAlign="right"
                                  iconName="external"
                                  onClick={()=>window.open("https://content.builder.brickformation.wwps.aws.dev/workshops/API204/test/page.html", '_blank')}
                >Test your architecture</Button>
              </SpaceBetween>
            </TextContent>
          ) : (
            ""
          )}
          {/*<div>
        <Box variant="awsui-key-label">Actions</Box>
        <div>
        </div>
    </div>*/}
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Preview</Box>
            <div>
              {
                //previewImageUrl !== "" ? (
                // <img src={previewImageUrl} alt="preview" />
                //) : (
                <img
                  src={props.previewImageUrl || "your_architecture.png"}
                  alt="preview"
                  className="preview-image"
                />
                //)
              }
            </div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}

export default BuildYourArchitecture;
