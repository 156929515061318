import React from "react";
import {
    Container,
    SpaceBetween,
    Header
  } from "@cloudscape-design/components";
  import { QRCodeSVG } from "qrcode.react";
  import Icon from "@cloudscape-design/components/icon";

function WorkshopHeader(props) {
  return (
    <Container header={<h2>Welcome to our builders session!</h2>}>
      <p>During this session, you will capture images of the architectures you build with toy bricks, which will then be recoginized and transformed to actual infrastructure.
        We've created a convenient way for you to capture images using your own smartphone. Our workshop's dedicated Progressive Web App (PWA) allows you to seamlessly take photos and interact with the content.</p>
      <h3>Pair your phone</h3>
      <p>Scan this QR-Code to pair your phone:</p>
        <QRCodeSVG
          value={
            props.pwaURL + props.registration
          }
        />
        <p><Icon name="status-warning" /> Please ensure that your smartphone's camera is functional and that you have a stable internet connection.</p>
        <p>
          You might install the web page on your phone as a "Progessive Web
          App", which adds it to your home screen.
        </p>
        <Header variant="h4">iOS</Header>
        <p>
          In Safari, press the "Share" button and select "Add to Home Screen"
          from the popup. Then, tap "Add" in the top right corner to finish
          installing the PWA. It will now be on your home screen.
        </p>
        <Header variant="h4">Android</Header>
        <p>
          In Chrome, press the "three dot" icon in the upper right to open the
          menu. Select "Add to Home screen." Press the "Add" button in the
          popup. The PWA is now installed and available on your home screen.
        </p>
    
    </Container>
  )
}

export default WorkshopHeader;