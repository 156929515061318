import "@cloudscape-design/global-styles/index.css";
import "./App.css";
import { useState, useEffect } from "react";
import AppLayout from "@cloudscape-design/components/app-layout";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Alert from "@cloudscape-design/components/alert";
import LoginPage from "./components/loginPage";
import WorkshopHeader from "./components/workshopHeader";
import Sockette from "sockette";
import BuildYourArchitecture from "./components/buildYourArchitecture";
import AppPairing from "./components/appPairing";
import AppPairingContainer from "./components/appPairingContainer";
import * as DOMPurify from "dompurify";

let ws = null;

const { WS_ENDPOINT, API_ENDPOINT, CONTENT_REPO, PWA } =
  window.config[window.location.hostname];

function App() {
  const [step, setStep] = useState("initial");
  const [eventName, setEventName] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [eventData, setEventData] = useState({});
  const [wsContent, setWsContent] = useState("");
  const [buildStep, setBuildStep] = useState("1");
  const [validationResult, setValidationResult] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [totalSections, setTotalSections] = useState(null);
  const [byaKey, setByaKey] = useState("0");
  const [registration, setRegistration] = useState(
    localStorage.getItem("registration") || ""
  );
  const [deploymentPercentage, setDeploymentPercentage] = useState(0);
  const [deploymentMessage, setDeploymentMessage] = useState("Initializing...");
  const [isSelfPaced, setIsSelfPaced] = useState("false");

  const handleUpdateEvent = async (data) => {
    setEventData({
      ...eventData,
      ...data,
    });
    if (data.status === "started" && data.currentSection > -1) {
      setCurrentSection(data.currentSection);
      setTotalSections(data.totalSections);
      setBuildStep("1");
      setValidationResult(false);
      setPreviewImageUrl(null);
      setByaKey((Math.random() + 1).toString(36).substring(7));
      console.log("data", data.template, "eventData", eventData.template);
      await downloadWsContent(
        CONTENT_REPO +
          "workshops/" +
          (data.template || eventData.template) +
          "/steps/" +
          data.currentSection +
          "/content.html"
      );
      setStep("work-on-task");
    }
    if (data.status === "started" && data.currentSection == -1) {
      setTotalSections(data.totalSections);
      setStep("event-started-wait");
    }
    if (data.status === "stopped") {
      setTotalSections(data.totalSections);
      setStep("event-stopped");
    }
  };

  const handleDeploymentStatusChange = (status) => {
    if (status === "CONTAINER_STARTED") {
      setDeploymentPercentage(10);
      setDeploymentMessage("Preparing deployment...");
    }
    if (status === "TEMPLATE_DOWNLOADED") {
      setDeploymentPercentage(20);
      setDeploymentMessage("Preparing deployment...");
    }
    if (status === "TEMPLATE_INSTALLED") {
      setDeploymentPercentage(40);
      setDeploymentMessage("Doing some magic...");
    }
    if (status === "DEPLOYMENT_DONE") {
      setDeploymentPercentage(100);
      setDeploymentMessage("Done!");
    }
  };

  const getEventStatus = async () => {
    const response = await fetch(API_ENDPOINT + "eventStatus", {
      method: "GET",
      headers: {
        "x-brickformation-auth": localStorage.getItem("x-brickformation-auth"),
      },
    });
    const status = await response.json();
    console.log(status);
    setEventName(status.event.eventName);
    setIsSelfPaced(status.event.selfPaced);
    handleUpdateEvent(status.event);
  };

  useEffect(() => {
    const messageHandler = async (m) => {
      console.log("Message", m);
      const data = JSON.parse(m.data);
      if (data.action === "IMAGE_UPLOADED") {
        setPreviewImageUrl(data.preview);
        setValidationResult(false);
        setBuildStep("2");
      }
      if (data.action === "UPDATE_EVENT") {
        handleUpdateEvent(data);
      }
      if (data.action === "DEPLOYMENT_STATUS_CHANGED") {
        handleDeploymentStatusChange(data.status);
      }
    };
    const onOpen = (e) => {
      console.log("connected:", e);
    };
    const onError = (e) => {
      console.log("ws error:", e);
    };
    if (loggedIn) {
      window.ws = new Sockette(
        WS_ENDPOINT + "?auth=" + localStorage.getItem("x-brickformation-auth"),
        {
          timeout: 5e3,
          //maxAttempts: 10,
          onopen: (e) => onOpen(e),
          onmessage: (e) => messageHandler(e),
          onreconnect: (e) => console.log("Reconnecting...", e),
          onmaximum: (e) => console.log("Stop Attempting!", e),
          onclose: (e) => {window.ws.reconnect(); console.log("DEBUG: Websocket closed. Please reload page. This should not happen."); console.log("Closed!", e)},
          onerror: (e) => onError(e),
        }
      );
      return function cleanup() {
        ws && ws.close();
        ws = null;
      };
    }
  }, [loggedIn]);

  const testAuth = async () => {
    console.log("testAuth called");
    return new Promise((resolve, reject) => {
      try {
        fetch(API_ENDPOINT + "testAuth", {
          method: "POST",
          body: JSON.stringify({}),
          headers: {
            "x-brickformation-auth": localStorage.getItem(
              "x-brickformation-auth"
            ),
          },
        }).then((response) => {
          if (response.status === 200) {
            setLoggedIn(true);
            getEventStatus();
            console.log("resolve");
            resolve();
          } else {
            setStep("login");
            console.log("reject");

            reject();
          }
        });
      } catch (e) {
        setStep("login");
        console.log("reject2");

        reject();
      }
    });
  };

  const login = async (registration) => {
    const response = await fetch(API_ENDPOINT + "registration", {
      method: "POST",
      body: JSON.stringify({ registrationKey: registration }),
    });
    if (response.status === 200) {
      const data = await response.json();
      localStorage.setItem("x-brickformation-auth", data.token);
      setRegistration(registration);
      localStorage.setItem("registration", registration);
      getEventStatus();
      setLoggedIn("true");
      setStep("wait-next-task");
    } else {
      alert("Something went wrong. Please check your registration ID"); // replace by a nicer message in UI
    }
  };

  const requestImage = async () => {
    await fetch(API_ENDPOINT + "requestImage", {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "x-brickformation-auth": localStorage.getItem("x-brickformation-auth"),
      },
    });
  };

  const downloadWsContent = async (url) => {
    let res = await fetch(url);
    setWsContent(await res.text());
  };

  const validate = async () => {
    setDeploymentPercentage(0);
    const imageBucket = previewImageUrl.match(/\/\/(.*)\.s3/)[1];
    const imageKey = previewImageUrl.match(/\.com\/(.*)\?/)[1];
    const response = await fetch(API_ENDPOINT + "analyzeImage", {
      method: "POST",
      body: JSON.stringify({ imageBucket, imageKey }),
      headers: {
        "x-brickformation-auth": localStorage.getItem("x-brickformation-auth"),
      },
    });
    const result = await response.json();
    console.log("Validate:", result);
    setPreviewImageUrl(result.result.annotatedImage);
    setValidationResult(result.result);
  };

  const deploy = async () => {
    setDeploymentMessage("Initializing...");
    setBuildStep("3");
    const response = await fetch(API_ENDPOINT + "deployStack", {
      method: "POST",
      body: JSON.stringify({ section: currentSection.toString() }),
      headers: {
        "x-brickformation-auth": localStorage.getItem("x-brickformation-auth"),
      },
    });
    const result = await response.json();
    console.log("Deploy:", result);
  };

  const openPostRequestInNewTab = (url, data) => {
    // Create a form element
    const form = document.createElement("form");

    // Set the form attributes
    form.method = "POST";
    form.action = url;
    form.target = "_blank"; // Open in a new tab

    // Add hidden input fields for data
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }
    }

    // Append the form to the document and submit it
    document.body.appendChild(form);
    form.submit();

    // Clean up: remove the form after submission
    document.body.removeChild(form);
  };

  const federate = async () => {
    const response = await fetch(API_ENDPOINT + "federate", {
      method: "POST",
      headers: {
        "x-brickformation-auth": localStorage.getItem("x-brickformation-auth"),
      },
    });
    const result = await response.json();
    openPostRequestInNewTab("https://signin.aws.amazon.com/federation", {
      Action: "login",
      Issuer: "https://example.com",
      Destination: "https://eu-central-1.console.aws.amazon.com/cloudformation/home",
      SigninToken: result.SigninToken,
    });
  };

  let content = <p />;
  if (step === "initial") {
    testAuth()
      .then(() => {
        setStep("wait-next-task");
      })
      .catch((e) => {
        console.log(e);
      });
  }
  if (step === "login") {
    content = <LoginPage action={login} />;
  }
  if (step === "event-stopped") {
    content = (
      <SpaceBetween size="l">
        <Alert
          statusIconAriaLabel="Info"
          header="Please wait for the event to start..."
        >
          The instructor will start the event shortly. This workshop consists of
          a series of tasks, which can be completed using the toy bricks
          provided. You can scan the architectures you have built with the
          companion app and validate and deploy your results here.
        </Alert>
        <AppPairingContainer registration={registration} pwaURL={PWA} />
      </SpaceBetween>
    );
  }
  if (step === "event-started-wait") {
    content = (
      <SpaceBetween size="l">
        <Alert statusIconAriaLabel="Info" header="Your event has started!">
          Please listen to the instructor and wait for the next task.
        </Alert>
        <AppPairingContainer registration={registration} pwaURL={PWA} />
      </SpaceBetween>
    );
  }

  if (step === "work-on-task") {
    content = (
      <SpaceBetween size="s">
        <Container header={<Header variant="h2">Your Task</Header>}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(wsContent).toString(),
            }}
            className="wscontent"
          ></div>
        </Container>{" "}
        <BuildYourArchitecture
          key={byaKey}
          requestImage={() => requestImage()}
          previewImageUrl={previewImageUrl}
          buildStep={buildStep}
          currentSection={currentSection - 1}
          retry={() => {
            setBuildStep("1");
            setPreviewImageUrl(null);
            setValidationResult(null);
            requestImage();
          }}
          validate={() => validate()}
          deploy={() => deploy()}
          validationResult={validationResult}
          deploymentPercentage={deploymentPercentage}
          deploymentMessage={deploymentMessage}
          federate={() => federate()}
        />
      </SpaceBetween>
    );
  }

  return (
    <AppLayout
      contentType="dashboard"
      navigationHide={true}
      content={
        <ContentLayout
          header={
            <>
              <div className="workshopHeader">
                <h1>
                  <img src="bricks.png" alt="logo" width="29" height="29" />{" "}
                  Brick Builders Session
                </h1>
              </div>
            </>
          }
        >
          <SpaceBetween size="l">
            {eventData.status !== undefined ? (
              <WorkshopHeader
                eventData={eventData}
                eventName={eventName}
                currentSection={currentSection}
                totalSections={totalSections}
                deployProgress={
                  buildStep === "3"
                    ? (1 / totalSections) * deploymentPercentage
                    : 0
                }
              />
            ) : null}
            {content}
          </SpaceBetween>
        </ContentLayout>
      }
      tools={
        <AppPairing registration={registration} federate={() => federate()} pwaURL={PWA} />
      }
    ></AppLayout>
  );
}

export default App;
