import { useState, useEffect, useRef } from "react";
import Header from "@cloudscape-design/components/header";
import Button from "@cloudscape-design/components/button";
import HelpPanel from "@cloudscape-design/components/help-panel";


import { QRCodeSVG } from "qrcode.react";

function AppPairing(props) {
  const [openAWSloading, setOpenAWSloading] = useState(false);
  return (
    <HelpPanel
      footer={
        <div>
          <h3>
            Temporary AWS Account
          </h3>
          <p>
            During this session, we are offering you access to a temporary AWS
            account. This account will be utilized for deploying your
            architecture. Please click the button below to log in to this
            account.
          </p>
          <Button
                  loading={openAWSloading}
                  onClick={async () => {
                    setOpenAWSloading(true);
                    await props.federate();
                    setOpenAWSloading(false);
                  }}
                  iconAlign="right"
                  iconName="external"
                >
                  Open AWS Account
                </Button>
        </div>
      }
      header={<h2>Info</h2>}
    >
      <div>
        <Header variant="h3">Pair your phone</Header>

        <p>Scan this QR-Code to pair your phone.</p>
        <QRCodeSVG
          value={
            props.pwaURL + props.registration
          }
        />
        <p>
          You might install the web page on your phone as a "Progessive Web
          App", which adds it to your home screen.
        </p>
        <Header variant="h4">iOS</Header>
        <p>
          In Safari, press the "Share" button and select "Add to Home Screen"
          from the popup. Then, tap "Add" in the top right corner to finish
          installing the PWA. It will now be on your home screen.
        </p>
        <Header variant="h4">Android</Header>
        <p>
          In Chrome, press the "three dot" icon in the upper right to open the
          menu. Select "Add to Home screen." Press the "Add" button in the
          popup. The PWA is now installed and available on your home screen.
        </p>
      </div>
    </HelpPanel>
  );
}

export default AppPairing;
